<template>
  <div>
    <app-toolbar class="admin_toolbar" extended />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <!-- <div v-if="$route.path !== '/' && $route.path !== '/schedule/'" class="text-center my-3">
        <router-link :to="{ name: 'TopIndex' }">{{ $t('back_to_top') }}</router-link>
      </div> -->
      <!-- App Footer -->
      <v-footer height="auto" class="footer app--footer" v-if="$route.path !== '/schedule/'">
        <div class="footer-inner">
          <div class="footer-list">
            <span v-if="$route.path === '/terms/'">{{ $t('menu.terms_index') }}</span>
            <router-link v-else :to="{ name: 'TermsIndex' }">{{ $t('menu.terms_index') }}</router-link>
            <span v-if="$route.path === '/policy/'">{{ $t('menu.policy_index_short') }}</span>
            <router-link v-else :to="{ name: 'PolicyIndex' }">{{ $t('menu.policy_index_short') }}</router-link>
            <span v-if="$route.path === '/inquiry/'">{{ $t('menu.inquiry') }}</span>
            <router-link v-else :to="{ name: 'InquiryIndex' }">{{ $t('menu.inquiry') }}</router-link>
          </div>
          <div class="footer-copy txt-md">
            &copy; かみしほろルーラルOS推進協議会 {{ new Date().getFullYear() }}
          </div>
        </div>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab v-if="$route.path !== '/schedule/'" />
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppToolbar,
    AppFab,
  },

  data() {
    return {
      isDrawerShown: true,
    }
  },
}
</script>
