<template>
  <div>
    <app-toolbar class="admin_toolbar" extended />
    <!-- <v-app style="background-color: #eee; background-image: url(/static/top.jpg); background-size: 100% auto"> -->
    <v-app>
      <v-main>
        <div class="page_wrapper"><router-view :key="$route.path"></router-view></div>
        <v-footer height="auto" class="footer app--footer">
          <div class="footer-inner">
            <div class="footer-list">
              <span v-if="$route.path === '/terms/'">{{ $t('menu.terms_index') }}</span>
              <router-link v-else :to="{ name: 'TermsIndex' }">{{ $t('menu.terms_index') }}</router-link>
              <span v-if="$route.path === '/policy/'">{{ $t('menu.policy_index_short') }}</span>
              <router-link v-else :to="{ name: 'PolicyIndex' }">{{ $t('menu.policy_index_short') }}</router-link>
              <span v-if="$route.path === '/inquiry/'">{{ $t('menu.inquiry') }}</span>
              <router-link v-else :to="{ name: 'InquiryIndex' }">{{ $t('menu.inquiry') }}</router-link>
            </div>
            <div class="footer-copy txt-md">&copy; かみしほろルーラルOS推進協議会 {{ new Date().getFullYear() }}</div>
          </div>
        </v-footer>
      </v-main>
    </v-app>
    <app-fab />
  </div>
</template>

<script>
import '../../theme/style.scss'
import AppToolbar from '@/components/AppToolbar'
import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutAuth',
  components: {
    AppToolbar,
    AppFab,
  },
  data: () => ({}),
  methods: {},
}
// export default {
//   data: () => ({}),
//   methods: {},
// }
</script>
<style lang="sass" scoped>
.layout-auth
  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0
</style>
