<template>
  <v-app dark>
    <router-view @theme-settings-click="handleThemeSettings"></router-view>
    <!-- global snackbar -->
    <v-snackbar v-model="snackbar.show" :timeout="3000" bottom right :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark text icon @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
      <template #action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="$store.commit('HIDE_SNACKBAR')">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import colors from 'vuetify/es5/util/colors'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar
      },
      set(val) {
        this.$store.commit('setSnackbar', val)
      },
    },
  },

  watch: {
    $route(to) {
      const params = Object.keys(this.$route.params).length ? this.$route.params : null
      const metaTitle = this.getMetatitle(to, params)
      const metaDescription = this.getMetaDescription(to, params)

      document.title = metaTitle
      document.querySelector("meta[property='og:title']").setAttribute('content', metaTitle)
      document.querySelector("meta[name='description']").setAttribute('content', metaDescription)
      document.querySelector("meta[property='og:description']").setAttribute('content', metaDescription)
    },
  },

  created() {
    // this.$vuetify.theme.themes.light.primary = colors['green'].base
    // this.$vuetify.theme.themes.light.accent = colors['green'].lighten3
    this.$vuetify.theme.themes.dark.primary = colors['indigo'].base
    this.$vuetify.theme.themes.dark.accent = colors['indigo'].darken3
    this.$vuetify.theme.dark = false
  },

  methods: {
    handleThemeSettings() {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },

    getMetatitle(to, params) {
      if (to.meta.mTitle && params) {
        const regexTitleResult = to.meta.mTitle.match(/:((?:[^/]+?))(?:\/(?=$))?$/i)

        if (regexTitleResult) {
          const titleQueryParam = params[regexTitleResult[1]]
          to.meta.mTitle = to.meta.mTitle.replace(regexTitleResult[0], titleQueryParam)
        }
      }

      const metaTitle =
        to.meta.title && to.meta.title != 'top_index' && this.$t(`menu.${to.meta.title}`)
          ? this.$t(`menu.${to.meta.title}`) + ' | かみしほろスマートPASS'
          : 'かみしほろスマートPASS'

      return to.meta.mTitle ? this.$t(`${to.meta.mTitle}`) + ' | かみしほろスマートPASS' : metaTitle
    },

    getMetaDescription(to, params) {
      if (to.meta.description && params) {
        const regexDescriptionResult = to.meta.description.match(/:((?:[^/]+?))(?:\/(?=$))?$/i)

        if (regexDescriptionResult) {
          const descriptionQueryParam = params[regexDescriptionResult[1]]
          to.meta.description = to.meta.description.replace(regexDescriptionResult[0], descriptionQueryParam)
        }
      }

      const metaDescription =
        to.meta.description && this.$t(`${to.meta.description}`)
          ? this.$t(`${to.meta.description}`)
          : this.$t(`meta.description.default`)

      return metaDescription
    },
  },
}
</script>
