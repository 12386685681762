<template>
  <v-menu offset-y origin="center center" rounded transition="scale-transition">
    <template #activator="{ on }">
      <v-btn slot="activator" text v-on="on" class="btn-locale">
        <v-icon medium>translate</v-icon>
        <span class="ml-2 d-md-none d-lg-flex"> Language </span>
        <span class="ml-2"> {{ $t($i18n.locale) }} </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="$i18n.locale">
        <v-list-item
          v-for="(value, key) in getAvailableLanguages"
          :key="key"
          :value="key"
          :disabled="$i18n.locale == key"
          @click="handleChangeLocale(key)"
        >
          <v-list-item-title v-text="value" />
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    getAvailableLanguages() {
      return {
        ja: this.$t('ja'),
        en: this.$t('en'),
      }
    },
  },
  methods: {
    handleChangeLocale(key) {
      this.$store.commit('setLocale', key)
    },
  },
}
</script>
