import Vue from 'vue'

import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'
import store from '@/store'
import i18n from './i18n'
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: store.getters.getThemeColor in colors ? colors[store.getters.getThemeColor].base : '#3F51B5',
        primary: '#26814D',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#EC0000',
        info: '#0877D7',
        success: '#259D63',
        warning: '#E25100',
      },
      // dark: {
      //   primary: '#26814D',
      //   secondary: '#424242',
      //   accent: '#82B1FF',
      //   error: '#FF5252',
      //   info: '#2196F3',
      //   success: '#4CAF50',
      //   warning: '#FFC107',
      // },
    },
  },
})
